import React, { useState } from 'react';
import { BestCluster } from '../../Models/BestCluster';
import { DeviceModel, DeviceModelIdMap } from '../../Models/SupportedDeviceModels';
import { Mode, Side } from '../../Models/SupportedSides';
import recEngingPreprocesor from 'otc-tool-recommendationenginepreprocessor-npm';
import { SelectChangeEvent } from '@mui/material';

export type RecommendationHandler = {
    mode: Mode;
    deviceModel: DeviceModel;
    frequencySets: number[][];
    bestClusters: BestCluster[] | undefined;
    hearingLossResult: string;
    clusterError: string;
    handlerModeChange: ((event: SelectChangeEvent<Mode>, child: React.ReactNode) => void) | undefined;
    handleDeviceModelChange: ((event: SelectChangeEvent<DeviceModel>, child: React.ReactNode) => void) | undefined;
    handleFrequencyChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        side: Side,
        index: number
    ) => void;
    generateBestCluster: () => void;
};

function useRecommendationHandler(): RecommendationHandler {
    const [mode, setMode] = useState<Mode>(Mode.Binaural);
    const [deviceModel, setDeviceModel] = useState<DeviceModel>(DeviceModel.OrionAir);
    const [frequencySets, setFrequencySets] = useState<number[][]>(
        [[0, 0, 0, 0], [0, 0, 0, 0]]
    );
    const [bestClusters, setBestClusters] = useState<BestCluster[] | undefined>();
    const [clusterError, setClusterError] = useState<string>('');
    const [hearingLossResult, setHearingLossResult] = useState<string>('');

    const handlerModeChange = (e: SelectChangeEvent<string>) => {
        const updatedMode = e.target.value as Mode;
        setMode(updatedMode);
    };

    const handleDeviceModelChange = (e: SelectChangeEvent<string>) => {
        const updatedDeviceModel = e.target.value as DeviceModel;
        setDeviceModel(updatedDeviceModel);
    };

    const handleFrequencyChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        side: Side,
        index: number
    ) => {
        const updatedInput = [...frequencySets];
        const frequency = parseInt(e.target.value, 10);
        updatedInput[side][index] = frequency;
        setFrequencySets(updatedInput);
    };

    const getClusterForOneSide = (): BestCluster | undefined => {
        const deviceModelId = DeviceModelIdMap[deviceModel];
        const frequencies = frequencySets[Side.Left];
        const result = recEngingPreprocesor.getRecommendedClusterMonaural(deviceModelId, frequencies);
        if (!result) return undefined;
        return {
            clusterNumber: result.ClusterNumber,
            masterGainId: result.MasterGainId,
            masterGainUiMaxId: result.MasterGainUiMaxId,
            masterGainUiMinId: result.MasterGainUiMinId,
            pureToneAverage: result.PureToneAverage,
        }; 
    };

    const getClusterForBothSides = (): [BestCluster[], boolean, boolean] | undefined => {
        const deviceModelId = DeviceModelIdMap[deviceModel];
        const [leftFrequencies, rightFrequencies] = frequencySets;
        const result = recEngingPreprocesor.getRecommendedClusterBinaural(deviceModelId, {
            Left: leftFrequencies,
            Right: rightFrequencies
        });
        if (!result || !result.Left || !result.Right) return undefined;
        const {Left: leftResult, Right: rightResult} = result;
        const clusters: [BestCluster, BestCluster] = [
            {
                clusterNumber: leftResult.ClusterNumber,
                masterGainId: leftResult.MasterGainId,
                masterGainUiMaxId: leftResult.MasterGainUiMaxId,
                masterGainUiMinId: leftResult.MasterGainUiMinId,
                pureToneAverage: leftResult.PureToneAverage,
            },
            {
                clusterNumber: rightResult.ClusterNumber,
                masterGainId: rightResult.MasterGainId,
                masterGainUiMaxId: rightResult.MasterGainUiMaxId,
                masterGainUiMinId: rightResult.MasterGainUiMinId,
                pureToneAverage: rightResult.PureToneAverage,
            },
        ];

        return [clusters, result.isSevereHearingLoss, result.isMismatchLoss];
    };

    const generateBestCluster = (): void => {
        let clusters: BestCluster[] = [];
        let hearingLoss = '';
        let error = '';
        const severeHearingLoss = 'Severe Hearing Loss';
        const mismatchHearingLoss = 'Mismatch Hearing Loss';
        const errorMessage = 'Invalid Audiogram';
        if (mode === Mode.Monaural) {
            const cluster = getClusterForOneSide();
            if (cluster) {
                clusters.push(cluster)
            } else {
                error = errorMessage;
            }
        } else {
            const result = getClusterForBothSides();
            if (result) {
                const [clusterResult, isSevereHearingLoss, isMismatchLoss] = result;
                clusters = clusterResult;
                hearingLoss = isSevereHearingLoss 
                    ? severeHearingLoss
                    : isMismatchLoss
                    ? mismatchHearingLoss
                    : '';
            } else {
                error = errorMessage;
            }
        }
        setBestClusters(clusters);
        setHearingLossResult(hearingLoss);
        setClusterError(error);
    };

    return {
        mode,
        deviceModel,
        frequencySets,
        bestClusters,
        hearingLossResult,
        clusterError,
        handlerModeChange,
        handleDeviceModelChange,
        handleFrequencyChange,
        generateBestCluster,
    };

  }

  export default useRecommendationHandler;