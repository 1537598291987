export enum Side {
    Left = 0,
    Right = 1
}

export const SupportedSides = [
    Side.Left,
    Side.Right
]

export enum Mode {
    Monaural = 'Monaural',
    Binaural = 'Binaural'
}

export const SupportedModes = [
    Mode.Monaural,
    Mode.Binaural
]