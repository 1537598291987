import { FormControl, TextField, Typography } from "@mui/material";

interface ToneSequenceInputProps {
    toneSequence: string;
    handleToneSequenceChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
} 

function ToneSequenceInput({
  toneSequence, 
  handleToneSequenceChange
}: ToneSequenceInputProps) {
  return (
        <FormControl fullWidth>
            <Typography variant='body2' className="hint">
                Tone seqeuence separated by comma
            </Typography>
            <TextField
                id='tone-input'
                type='text'
                label='Tone Sequence'
                value={toneSequence}
                onChange={handleToneSequenceChange}
                variant='outlined'
            />
        </FormControl>
  )
}

export default ToneSequenceInput;