import { Paper, Typography } from "@mui/material";

interface StateResultProps {
    result: string;
}

function StateResult({result}: StateResultProps) {
    return (
        <Paper elevation={3} id='result-card' className='infoCard resultCard'>
            <Typography className='text' style={{ fontWeight: 'bold'}}>Result</Typography>
            <Typography className='text' style={{ paddingTop: '4px'}}>{result}</Typography>
        </Paper>
    )
}

export default StateResult;