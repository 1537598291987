import { Grid, TextField } from "@mui/material";
import { HearingProfile } from "../../Models/HearingProfile";

interface HearingProfileInputProps {
    hearingProfile: HearingProfile;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

function HearingProfileInput({
    hearingProfile,
    handleInputChange
}: HearingProfileInputProps) {
    const labels = ['Band 1', 'Band 2', 'Band 3', 'Band 4', 'Min Master Gain', 'Max Master Gain', 'Master Gain'];

    return (
        <>
            {Object.keys(hearingProfile).map((property, index) => (
                <Grid item xs={6} key={index}>
                    <TextField
                        label={labels[index]}
                        type='number'
                        name={property}
                        value={hearingProfile[property as keyof HearingProfile]}
                        variant='outlined'
                        className='hearingProfileInput'
                        onChange={handleInputChange}
                    />
                </Grid>
            ))}
        </>
    )
}

export default HearingProfileInput;