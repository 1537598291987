import { Typography, Paper } from "@mui/material";
import { HearingProfile } from "../../Models/HearingProfile";

interface HearingProfileResultProps {
    hearingProfile: HearingProfile
}

const HearingProfileResult = ({ hearingProfile }: HearingProfileResultProps) => {
    const {
        band1,
        band2,
        band3,
        band4,
        masterGain
    } = hearingProfile;

    return (
        <div className='hearingProfileResult'>
            <Typography variant='body2' className="hint">Current Hearing Profile</Typography>
            <Paper elevation={3} id='hearing-profile-result' className='hearingProfileCard'>
                <Typography variant='body1' className='text'>Band 1: {band1}</Typography>
                <Typography variant='body1' className='text'>Band 2: {band2}</Typography>
                <Typography variant='body1' className='text'>Band 3: {band3}</Typography>
                <Typography variant='body1' className='text'>Band 4: {band4}</Typography>
                <Typography variant='body1' className='text'>Master Gain: {masterGain}</Typography>
            </Paper>
        </div>
    );
};

export default HearingProfileResult;
