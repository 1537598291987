import React from 'react'
import { Button, Grid } from '@mui/material';
import CategoryAndProblemSelection from './CategoryAndProblemSelection';
import ModelSelection from '../SharedComponents/ModelSelection';
import useFineTuningHandler from './FineTuningHandler';
import HearingProfileInput from './HearingProfileInput';
import '../SharedComponents/SharedStyle.css';
import './FineTuningStyle.css';
import SolutionCarousel from './SolutionCarousel';
import HearingProfileResult from './HearingProfileResult';

function FineTuning() {
    const {
        deviceModel,
        category,
        categoryList,
        problem,
        problemList,
        hearingProfile,
        solutions,
        currentHearingProfile,
        handleDeviceModelChange,
        handleCategoryChange,
        handleProblemChange,
        handleInputChange,
        generateSolutions,
        applySolution
    } = useFineTuningHandler();

    const renderModelSelection = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <Grid item className='modelSelection'>
                    <ModelSelection 
                        deviceModel={deviceModel}
                        handleDeviceModelChange={handleDeviceModelChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderCategoryAndProblemSelection = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <Grid item>
                    <CategoryAndProblemSelection
                        category={category}
                        categoryList={categoryList}
                        problem={problem}
                        problemList={problemList}
                        handleCategoryChange={handleCategoryChange}
                        handleProblemChange={handleProblemChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderHearingProfileInput = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer hearingProfileContainer' spacing={3}>
                <HearingProfileInput
                    hearingProfile={hearingProfile}
                    handleInputChange={handleInputChange}
                />
            </Grid>   
        )
    }

    const renderGenerateSolutionsButton = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer solutionButton'>
                <Grid item>
                    <Button
                        id='get-state-button'
                        variant='contained' 
                        color='primary'
                        onClick={generateSolutions}
                    >
                        Generate Solutions
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const renderSolutionCarousel = (): React.ReactElement => {
        return (
            <>  
                {solutions.length > 0 && 
                    <Grid container className='mainContainer carouselContainer'>
                        <SolutionCarousel 
                            solutions={solutions}
                            applySolution={applySolution}
                        />
                    </Grid>
                }
            </>
        )
    }

    const renderHearingProfileResult = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <HearingProfileResult 
                    hearingProfile={currentHearingProfile}
                />
            </Grid>
        )
    }

    return (
        <Grid container justifyContent='center' alignItems='center' spacing={4}>
            <Grid item xs={10} sm={10} md={5} lg={5}>
                {renderModelSelection()}
                {renderCategoryAndProblemSelection()}
                {renderHearingProfileInput()}
                {renderGenerateSolutionsButton()}
            </Grid>
            <Grid item xs={10} sm={6} md={4} lg={3}>
                {renderHearingProfileResult()}
                {renderSolutionCarousel()}
            </Grid>
        </Grid>
    );
}

export default FineTuning;