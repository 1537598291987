import { useEffect, useState } from 'react';
import hlaaPreprocessor from 'otc-tool-hlaapreprocessor-npm';
import { DeviceModel, DeviceModelIdMap } from '../../Models/SupportedDeviceModels';
import { HLAAResult, SpecialWorkflow } from '../../Models/HLAAResult';
import { HAConfiguration } from '../../Models/HAConfiguration'; 
import { SelectChangeEvent } from '@mui/material';

export type HLAAHandler = {
    deviceModel: DeviceModel;
    frequency: number;
    progress: number;
    toneSequence: string;
    result: string;
    haConfiguration: HAConfiguration;
    handleDeviceModelChange: ((event: SelectChangeEvent<DeviceModel>, child: React.ReactNode) => void) | undefined;
    handleToneSequenceChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    getHLAAState: () => void;
};

function useHLAAHandler(): HLAAHandler {
    const [deviceModel, setDeviceModel] = useState<DeviceModel>(DeviceModel.OrionAir);
    const [frequency, setFrequency] = useState<number>(0);
    const [frequencyList, setFrequencyList] = useState<number[]>([0, 0, 0, 0]);
    const [progress, setProgress] = useState<number>(0);
    const [toneSequence, setToneSequence] = useState<string>('');
    const [result, setResult] = useState<string>('');
    const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
    const [haConfiguration, setHAConfiguration] = useState<HAConfiguration>(
        hlaaPreprocessor.getHAConfiguration(DeviceModelIdMap[DeviceModel.OrionAir])
    );

    useEffect(() => {
        const configuredFrequencies = hlaaPreprocessor.getConfiguredFrequencies(DeviceModelIdMap[deviceModel]);
        const frequencies = Object.values(configuredFrequencies);
        setFrequencyList(frequencies);
        setFrequency(frequencies[1]);
        setProgress(0);
        setToneSequence('');
        setResult('');
    }, [deviceModel]);

    const handleDeviceModelChange = (e: SelectChangeEvent<string>) => {
        const updatedDeviceModel = e.target.value as DeviceModel;
        setHAConfiguration(hlaaPreprocessor.getHAConfiguration(DeviceModelIdMap[updatedDeviceModel]));
        setDeviceModel(updatedDeviceModel);
    };

    const handleToneSequenceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setToneSequence(e.target.value);
    };

    const getHLAAState = (): void => {
        const toneSequenceList = getToneSequenceList();
        if (!toneSequenceList) {
            setResult(HLAAResult.InvalidInput);
            return; 
        }
        
        const result = hlaaPreprocessor.getNextState(DeviceModelIdMap[deviceModel], frequency, toneSequenceList, isFirstRun);
        setResult(getResult(result));
    };

    const getToneSequenceList = (): number[] | null => {
        const arr = toneSequence.split(',');
        try {
            const toneSequenceList = arr.map(str => {
                const num = parseInt(str, 10);
                if (isNaN(num)) throw new Error('tone sequence can only contain digit');
                return num;
            });
            return toneSequenceList;
        } catch (error) {
            return null;
        }
    };

    const getResult = (result: any): string => {
        if (!result) {
            return HLAAResult.InvalidInput;
        } else if ('workflow' in result) {
            if (result.workflow === SpecialWorkflow.ConstantZeroToneHeard) {
                setIsFirstRun(false);
            }
            return `${HLAAResult.Workflow}: ${result.workflow}`;
        } else if ('toneSet' in result) {
            return `${HLAAResult.ToneSet}: ${result.toneSet}`;
        } else {
            updateFrequencyAndProgress();
            return `${HLAAResult.Threshold}: ${result.threshold}`;
        }
    }

    const updateFrequencyAndProgress = (): void => {
        const len = frequencyList.length;
        const freqIndex = frequencyList.indexOf(frequency);
        const index = (freqIndex + 1) % len;
        const progress = Math.ceil((freqIndex / len) * 100);
        setFrequency(frequencyList[index]);
        setProgress(progress);
    };
    
    return {
        deviceModel,
        frequency,
        progress,
        toneSequence,
        result,
        haConfiguration,
        handleDeviceModelChange,
        handleToneSequenceChange,
        getHLAAState
    };
}

export default useHLAAHandler;
