import React from "react";
import { Button, Grid, Typography } from '@mui/material';
import { Mode, Side, SupportedSides } from '../../Models/SupportedSides';
import AudiogramInput from './AudiogramInput';
import ClusterResult from './ClusterResult';
import ModeAndModelSelection from "./ModeAndModelSelection";
import useRecommendationHandler from './RecommendationHandler';
import '../SharedComponents/SharedStyle.css';
import './RecommendationStyle.css';

function Recommendation() {
    const {
        mode,
        deviceModel, 
        frequencySets, 
        bestClusters,
        hearingLossResult,
        clusterError,
        handlerModeChange,
        handleDeviceModelChange,
        handleFrequencyChange, 
        generateBestCluster 
    } = useRecommendationHandler();

    const renderModeAndModelSelection = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <Grid item>
                    <ModeAndModelSelection 
                        mode={mode}
                        deviceModel={deviceModel}
                        handlerModeChange={handlerModeChange}
                        handleDeviceModelChange={handleDeviceModelChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderClusterSection = (): React.ReactElement => {
        return (
            <>
                {SupportedSides.map((side, index) => (
                    <Grid key={side} item xs={12} sm={6} md={4} lg={3}>
                        <Grid container className='mainContainer'>
                            <Grid item>
                                <AudiogramInput
                                    disabled={mode === Mode.Monaural && side === Side.Right}
                                    model={deviceModel}
                                    side={side}
                                    frequencies={frequencySets[index]}
                                    handleFrequencyChange={handleFrequencyChange}
                                />
                                <div className='clusterSection'>
                                    {bestClusters && bestClusters[index] && (
                                        <ClusterResult bestCluster={bestClusters[index]} />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </>
        )
    }

    const renderClusterError = (): React.ReactElement => {
        return (
            <>
                {clusterError && (
                    <Grid container className='mainContainer message'>
                        <Grid item>
                            <Typography className='warning'>
                                {clusterError}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    const renderHearingLossResult = (): React.ReactElement => {
        return (
            <>
                {hearingLossResult && (
                    <Grid container className='mainContainer message'>
                        <Grid item>
                            <Typography className='warning'>
                               {hearingLossResult}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </>
        )
    }

    const renderGenerateButton = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <Grid item>
                    <Button
                        id='generate-button' 
                        variant='contained' 
                        color='primary' 
                        onClick={generateBestCluster}
                    >
                        Generate
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container justifyContent='center' alignItems='center'>
            {renderModeAndModelSelection()}
            {renderClusterSection()}
            {renderClusterError()}
            {renderHearingLossResult()}
            {renderGenerateButton()}
        </Grid>
    )
}

export default Recommendation;