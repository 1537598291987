import { Button, Carousel } from 'react-bootstrap';
import { useState } from 'react';
import { Solution } from '../../Models/FineTuning';

interface SolutionCarouselProps {
    solutions: Solution[];
    applySolution: (solutionId: string) => void;
}

function SolutionCarousel({ solutions, applySolution }: SolutionCarouselProps) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number) => {
        console.log(selectedIndex);
        if ((selectedIndex === 0 && index === solutions.length - 1) ||
            (selectedIndex === solutions.length - 1 && index === 0)
        ) selectedIndex = index;
        setIndex(selectedIndex);
    };

    return (
        <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
            className='carousel'
        >
            {solutions.map((solution, idx) => (
                <Carousel.Item key={solution.Id}>
                    <Carousel.Caption className='text carouselCaption'>
                        <h6>Solution {idx + 1}</h6>
                        <strong>{solution.Name}</strong>
                    </Carousel.Caption>
                    <Button 
                        variant='primary'
                        onClick={() => applySolution(solution.Id)}
                        style={{marginTop: '60px'}}
                    >
                        Apply
                    </Button> 
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SolutionCarousel;
