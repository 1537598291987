import React from 'react'
import {
    Link
} from "react-router-dom";
import { 
    Container,
    Card,
    Col,
} from 'react-bootstrap';
import preprocessorsData from '../preprocessors.json';

function Home() {

    const options = preprocessorsData;

    const optionCard = (
        route: string, 
        displayName: string, 
        description: string, 
        status: string
    ) => {
        return(
            <Link 
                to={`/${route}`} 
                style={{ textDecoration: 'none' }}
            >
                <Card className='bg-light'>
                    <Card.Body>
                        <Card.Body className='bg-success text-white border border-dark mb-2'>
                            <Card.Title>{displayName}</Card.Title>
                        </Card.Body>
                        <Card.Body style={{ textAlign: 'left' }}>
                            <Card.Text>
                                <span style={{ fontWeight: 'bold' }}>
                                    Description:&nbsp;
                                </span>
                                {description}
                            </Card.Text>
                            <Card.Text
                                style={{ fontWeight: 'bold' }}
                            >
                                <span>
                                    Status:&nbsp;
                                </span>
                                <span 
                                    className={status === 'Live' ? 'text-success' : 'text-danger'}
                                >
                                    {status}
                                </span>    
                            </Card.Text>
                        </Card.Body>
                        <Card.Img 
                            src={`assets/${route}.png`} 
                            className='border border-dark'
                        />
                    </Card.Body>
                </Card>
            </Link>
        )
    }

    return (
        <Container 
            className='mt-2 d-flex gap-5 flex-column flex-lg-row justify-content-center align-items-center'
        >
            {
                options.map((option, key) => {
                    return (
                        <Col key={key}>
                            {
                                optionCard(
                                    option.route, 
                                    option.displayName, 
                                    option.description, 
                                    option.status
                                )
                            }
                        </Col>
                    );
                })
            }
        </Container>
    );
}

export default Home;