import { Paper, Typography } from "@mui/material";
import { HAConfiguration } from "otc-tool-hlaapreprocessor-npm";

interface HAConfigurationResultProps {
    haConfiguration: HAConfiguration;
}

function HAConfigurationResult({haConfiguration}: HAConfigurationResultProps) {
    return (
        <Paper elevation={3} id='configuration-card' className='infoCard configurationCard'>
            <Typography className='text' style={{ fontWeight: 'bold'}}>HA Configuration</Typography>
            <Typography className='text'>Coupling: {haConfiguration.Coupling}</Typography>
            <Typography className='text'>Master Gain Step Size: {haConfiguration.MasterGainStepSize}</Typography>
            <Typography className='text'>Master Gain Default Step: {haConfiguration.MasterGainDefaultStep}</Typography>
            <Typography className='text'>Master Gain Min UI: {haConfiguration.MasterGainMinUI}</Typography>
            <Typography className='text'>Master Gain Max UI: {haConfiguration.MasterGainMaxUI}</Typography>
        </Paper>
    )
}

export default HAConfigurationResult;