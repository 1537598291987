import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Category, Problem } from "../../Models/FineTuning";

interface CategoryAndProblemSelectionProps {
    category: Category | undefined;
    categoryList: Category[];
    problem: Problem | undefined;
    problemList: Problem[];
    handleCategoryChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
    handleProblemChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
}

function CategoryAndProblemSelection({
    category,
    categoryList,
    problem,
    problemList,
    handleCategoryChange,
    handleProblemChange
}: CategoryAndProblemSelectionProps) {
    return (
        <div className='selectionContainer'>
            <div className='categorySelection'>
                <FormControl fullWidth>
                    <InputLabel id='category-label'>Category</InputLabel>
                    <Select
                        id='category-selection'
                        value={category ? category.Name : ''}
                        onChange={handleCategoryChange}
                    >
                        {categoryList.map((value, index) => (
                            <MenuItem key={`category-${index}`} value={value.Name}>
                                {value.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='problemSelection'>
                <FormControl fullWidth>
                    <InputLabel id='device-label'>Description</InputLabel>
                    <Select
                        id='problem-selection'
                        value={problem ? problem.Name: ''}
                        onChange={handleProblemChange}
                    >
                        {problemList.map((value, index) => (
                            <MenuItem key={`problem-${index}`} value={value.Name}>
                                {value.Name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

export default CategoryAndProblemSelection;