import React from "react";
import { FormControl, TextField } from "@mui/material";
import { DeviceFrequencyMap } from "../../Models/SupportedFrequencies";
import { Side } from "../../Models/SupportedSides";
import { DeviceModel } from "../../Models/SupportedDeviceModels";


interface AudiogramInputProps {
    disabled?: boolean;
    model: DeviceModel;
    side: Side;
    frequencies: number[];
    handleFrequencyChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        side: Side,
        index: number
    ) => void;
} 
  
function AudiogramInput({
    disabled = false,
    model,
    side,
    frequencies,
    handleFrequencyChange,
}: AudiogramInputProps) {
return (
    <FormControl fullWidth>
        {frequencies.map((value, index) => (
            <div key={index} className='frequencySelection'>
                <TextField
                    disabled={disabled}
                    id={`frequency-${index}`}
                    type='number'
                    label={`Frequency ${DeviceFrequencyMap[model][index]}`}
                    value={value}
                    onChange={(e: any) => handleFrequencyChange(e, side, index)}
                    variant='outlined'
                />
            </div>
        ))}
    </FormControl>
);
}

export default AudiogramInput;