export enum DeviceModel {
    OrionAir = 'CRE-C10',
    OrionGo = 'CRE-E10',
    OrionC20 = 'CRE-C20',
    OrionT100 = 'CRE-T100'
}

export const SupportedDeviceModels = [
    DeviceModel.OrionAir,
    DeviceModel.OrionGo,
    DeviceModel.OrionC20,
    DeviceModel.OrionT100
]

export const DeviceModelIdMap = {
    [DeviceModel.OrionAir]: '6144007',
    [DeviceModel.OrionGo]: '540938247',
    [DeviceModel.OrionC20]: '72780372515670',
    [DeviceModel.OrionT100]: '72986808818502'
}