import { LinearProgressProps, LinearProgress } from "@mui/material";

interface ITopProgressBar {
  progress: number;
}

const TopProgressBar = ({ progress }: 
  Omit<LinearProgressProps, keyof ITopProgressBar> &
  ITopProgressBar) => {
  return (
    <LinearProgress
        id="top-progress-bar"
        variant="determinate"
        value={progress}
        className='progressBar'
    />
  );
}

export default TopProgressBar;