import { useEffect, useRef, useState } from "react";
import finetuningPreprocessor from 'otc-tool-finetuningpreprocessor-npm';
import { DeviceModel, DeviceModelIdMap } from '../../Models/SupportedDeviceModels';
import { Category, FourBandEqualizer, Offset, Problem, Solution } from "../../Models/FineTuning";
import { HearingProfile } from "../../Models/HearingProfile";
import { SelectChangeEvent } from "@mui/material";

export type FineTuningHandler = {
    deviceModel: DeviceModel;
    category: Category | undefined;
    categoryList: Category[];
    problem: Problem | undefined;
    problemList: Problem[];
    hearingProfile: HearingProfile;
    solutions: Solution[];
    currentHearingProfile: HearingProfile;
    handleDeviceModelChange: ((event: SelectChangeEvent<DeviceModel>, child: React.ReactNode) => void) | undefined;
    handleCategoryChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
    handleProblemChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void) | undefined;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    generateSolutions: () => void;
    applySolution: (solutionId: string) => void;
}

function useFineTuningHandler(): FineTuningHandler {
    const emptyHearingProfile = () => {
        return {band1: 0, band2: 0, band3: 0, band4: 0, minMasterGain: 0, maxMasterGain: 0, masterGain: 0}
    }
    const [deviceModel, setDeviceModel] = useState<DeviceModel>(DeviceModel.OrionAir);
    const [category, setCategory] = useState<Category>();
    const [categoryList, setCategoryList] = useState<Category[]>([]);
    const [problem, setProblem] = useState<Problem>();
    const [problemList, setProblemList] = useState<Problem[]>([]);
    const [hearingProfile, sethearingProfile] = useState<HearingProfile>(emptyHearingProfile());
    const [solutions, setSolutions] = useState<Solution[]>([]);
    const [currentHearingProfile, setCurrentHearingProfile] = useState<HearingProfile>(emptyHearingProfile());
    const currentSolutionId = useRef<string>("-1");

    useEffect(() => {
        const fetchData = () => {
            const categories = finetuningPreprocessor.getCategories(DeviceModelIdMap[deviceModel]) as Category[];
            const problems = finetuningPreprocessor.getProblems(DeviceModelIdMap[deviceModel], categories[0].Id) as Problem[];
            setCategoryList(categories);
            setProblemList(problems);
            if (categories.length > 0) {
                setCategory(categories[0]);
            }
            if (problems.length > 0) {
                setProblem(problems[0]);
            }
            setSolutions([]);
        };
    
        fetchData();
    }, [deviceModel])

    const handleDeviceModelChange = (e: SelectChangeEvent<string>) => {
        const updatedDeviceModel = e.target.value as DeviceModel;
        setDeviceModel(updatedDeviceModel);
    };

    const handleCategoryChange = (e: SelectChangeEvent<string>) => {
        const categoryName = e.target.value as string;
        const selectedCategory = categoryList.find(category => category.Name === categoryName);
        if (selectedCategory) {
            setCategory(selectedCategory);
            const problems = finetuningPreprocessor.getProblems(DeviceModelIdMap[deviceModel], selectedCategory.Id) as Problem[];
            setProblemList(problems);
            if (problems.length > 0) {
                setProblem(problems[0]);
            }
            setSolutions([]);
        }
    };

    const handleProblemChange = (e: SelectChangeEvent<string>) => {
        const problemName = e.target.value as string;
        const selectedProblem = problemList.find(problem => problem.Name === problemName);
        if (selectedProblem) {
            setProblem(selectedProblem);
            setSolutions([]);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        const updatedhearingProfile = {
            ...hearingProfile,
            [name]: value
        };
        sethearingProfile(updatedhearingProfile);
    }

    const generateSolutions = () => {
        if (!category || !problem) return;

        const band1 = isNaN(hearingProfile.band1) ? 0 : hearingProfile.band1;
        const band2 = isNaN(hearingProfile.band2) ? 0 : hearingProfile.band2;
        const band3 = isNaN(hearingProfile.band3) ? 0 : hearingProfile.band3;
        const band4 = isNaN(hearingProfile.band4) ? 0 : hearingProfile.band4;
        const masterGain = isNaN(hearingProfile.masterGain) ? 0 : hearingProfile.masterGain;

        setCurrentHearingProfile(prevProfile => ({
            ...prevProfile,
            band1: band1,
            band2: band2,
            band3: band3,
            band4: band4,
            masterGain: masterGain
        }));

        const fourBandEqualizer: FourBandEqualizer = {
            Band1: band1,
            Band2: band2,
            Band3: band3,
            Band4: band4
        }

        const solutions = finetuningPreprocessor.getSolutions(
            DeviceModelIdMap[deviceModel], 
            category.Id, 
            problem.Id,
            hearingProfile.masterGain,
            fourBandEqualizer,
            hearingProfile.minMasterGain,
            hearingProfile.maxMasterGain  
        ) as Solution[];

        setSolutions(solutions);
    }

    const applySolution = (solutionId: string) => {
        const offset = finetuningPreprocessor.getSolution(DeviceModelIdMap[deviceModel], solutionId) as Offset;
        let band1: number;
        let band2: number;
        let band3: number;
        let band4: number;
        let masterGain: number;
        
        if (currentSolutionId.current === solutionId) {
            band1 = currentHearingProfile.band1 + offset.Band1;
            band2 = currentHearingProfile.band2 + offset.Band2;
            band3 = currentHearingProfile.band3 + offset.Band3;
            band4 = currentHearingProfile.band4 + offset.Band4;
            masterGain = currentHearingProfile.masterGain + offset.MasterGain;
        } else {
            currentSolutionId.current = solutionId;
            band1 = hearingProfile.band1 + offset.Band1;
            band2 = hearingProfile.band2 + offset.Band2;
            band3 = hearingProfile.band3 + offset.Band3;
            band4 = hearingProfile.band4 + offset.Band4;
            masterGain = hearingProfile.masterGain + offset.MasterGain;
        }

        setCurrentHearingProfile(prevProfile => ({
            ...prevProfile,
            band1: band1,
            band2: band2,
            band3: band3,
            band4: band4,
            masterGain: masterGain
        }));
        
        const fourBandEqualizer: FourBandEqualizer = {
            Band1: band1,
            Band2: band2,
            Band3: band3,
            Band4: band4
        }

        const solutions = finetuningPreprocessor.getSolutions(
            DeviceModelIdMap[deviceModel], 
            category!.Id, 
            problem!.Id,
            masterGain,
            fourBandEqualizer,
            currentHearingProfile.minMasterGain,
            currentHearingProfile.maxMasterGain  
        ) as Solution[];

        setSolutions(solutions);
    }

    return {
        deviceModel,
        category,
        categoryList,
        problem,
        problemList,
        hearingProfile,
        solutions,
        currentHearingProfile,
        handleDeviceModelChange,
        handleCategoryChange,
        handleProblemChange,
        handleInputChange,
        generateSolutions,
        applySolution
    }
}

export default useFineTuningHandler;
