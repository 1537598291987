import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { DeviceModel, SupportedDeviceModels } from "../../Models/SupportedDeviceModels";

interface ModelSelectionProps {
    deviceModel: DeviceModel;
    handleDeviceModelChange: ((event: SelectChangeEvent<DeviceModel>, child: React.ReactNode) => void) | undefined;
} 

function ModelSelection({
    deviceModel, 
    handleDeviceModelChange
}: ModelSelectionProps) {
  return (
        <FormControl fullWidth>
            <InputLabel id='device-label'>Device</InputLabel>
            <Select
                id='model-selection'
                value={deviceModel}
                onChange={handleDeviceModelChange}
            >
                {SupportedDeviceModels.map((value, index) => (
                    <MenuItem key={`model-${index}`} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
  )
}

export default ModelSelection;