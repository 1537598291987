import React from 'react'
import HLAA from '../../Components/HLAA/HLAA';
import Recommendation from '../../Components/Recommendation/Recommendation';
import FineTuning from '../../Components/FineTuning/FineTuning';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './FeatureFrame.scss';

interface IFeatureFrameProps {
    route: string
}

function FeatureFrame({route}: IFeatureFrameProps) {
    const displayComponent = (route: string) => {
        switch(route) {
            case 'hlaa':
                return <HLAA />
            case 'recommendation':
                return <Recommendation/>
            case 'finetuning':
                return <FineTuning />
            default:
                return <h2>Component not found</h2>
        }
    };

    return (
        <>
            <header className='FeatureFrame-header'>
                <Container>
                    <Link 
                        to={'/'} 
                        style={{color: "white", textDecoration: 'none'}}
                    >
                        <Button 
                            variant='danger'
                            className='FeatureFrame-back-btn'
                        >
                            back
                        </Button>
                    </Link>
                </Container>
            </header>
            <body className='FeatureFrame-body'>
                {displayComponent(route)}
            </body>
        </>
    );
}

export default FeatureFrame;