import React from "react";
import { Typography, Paper } from "@mui/material";
import { BestCluster } from "../../Models/BestCluster";

interface ClusterResultProps {
    bestCluster: BestCluster;
}

const ClusterResult = ({ bestCluster }: ClusterResultProps) => {
  const {
    clusterNumber,
    masterGainId,
    masterGainUiMaxId,
    masterGainUiMinId,
    pureToneAverage,
  } = bestCluster;

  return (
    <Paper elevation={3} id='cluster-card' className='clusterCard'>
        <Typography variant='body1' className='text'>Cluster Number: {clusterNumber}</Typography>
        <Typography variant='body1' className='text'>Master Gain: {masterGainId}</Typography>
        <Typography variant='body1' className='text'>Max UI Master Gain: {masterGainUiMaxId}</Typography>
        <Typography variant='body1' className='text'>Min UI Master Gain: {masterGainUiMinId}</Typography>
        <Typography variant='body1' className='text'>Pure Tone Average: {pureToneAverage}</Typography>
    </Paper>
  );
};

export default ClusterResult;
