import { DeviceModel } from './SupportedDeviceModels';

export const DeviceFrequencyMap = {
    [DeviceModel.OrionAir]: [
        '750',
        '1.5k',
        '3k',
        '6k'
    ],
    [DeviceModel.OrionGo]: [
        '750',
        '1.5k',
        '3k',
        '6k'
    ],
    [DeviceModel.OrionC20]: [
        '750',
        '1.5k',
        '3k',
        '6k'
    ],
    [DeviceModel.OrionT100]: [
        '750',
        '1.5k',
        '3k',
        '6k'
    ]
}