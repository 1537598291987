import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useHLAAHandler from './HLAAHandler';
import ModelSelection from '../SharedComponents/ModelSelection';
import HAConfigurationResult from './HAConfigurationResult';
import StateResult from './StateResult';
import ToneSequenceInput from './ToneSequenceInput';
import TopProgressBar from './TopProgressBar';
import '../SharedComponents/SharedStyle.css';
import './HLAAStyle.css';

function HLAA() {
    const { 
        deviceModel,
        frequency,
        progress,
        toneSequence,
        result,
        haConfiguration,
        handleDeviceModelChange,
        handleToneSequenceChange,
        getHLAAState,
    } = useHLAAHandler();

    const renderModelSelection = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <Grid item className='modelSelection'>
                    <ModelSelection 
                        deviceModel={deviceModel}
                        handleDeviceModelChange={handleDeviceModelChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderHAConfiguration = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer resultContainer'>
                <Grid item>
                    <HAConfigurationResult haConfiguration={haConfiguration} />
                </Grid>
            </Grid>
        )
    }

    const renderTopProgressBar = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer progressBarArea'>
                <Grid item>
                    <TopProgressBar progress={progress} />
                    <Typography className='text paddingText'>Frequency: {frequency}</Typography>
                </Grid>
            </Grid>
        )
    }

    const renderToneSequenceSelection = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer'>
                <Grid item className='toneSequenceInput'>
                    <ToneSequenceInput
                        toneSequence={toneSequence}
                        handleToneSequenceChange={handleToneSequenceChange}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderStateResult = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer resultContainer'>
                <Grid item>
                    <StateResult result={result} />
                </Grid>
            </Grid>
        )
    }

    const renderGetStateButton = (): React.ReactElement => {
        return (
            <Grid container className='mainContainer buttonContainer'>
                <Grid item>
                    <Button
                        id='get-state-button'
                        variant='contained' 
                        color='primary' 
                        onClick={getHLAAState}
                    >
                        Get State
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container justifyContent='center' alignItems='center'>
            {renderModelSelection()}
            {renderHAConfiguration()}
            {renderTopProgressBar()}
            {renderToneSequenceSelection()}
            {renderStateResult()}
            {renderGetStateButton()}
        </Grid>
    );
}

export default HLAA;