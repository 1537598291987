import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { DeviceModel } from "../../Models/SupportedDeviceModels";
import { Mode, SupportedModes } from "../../Models/SupportedSides";
import ModelSelection from '../SharedComponents/ModelSelection';

interface ModeAndModelSelectionProps {
    mode: Mode;
    deviceModel: DeviceModel;
    handlerModeChange: ((event: SelectChangeEvent<Mode>, child: React.ReactNode) => void) | undefined;
    handleDeviceModelChange: ((event: SelectChangeEvent<DeviceModel>, child: React.ReactNode) => void) | undefined;
} 

function ModeAndModelSelection({
    mode, 
    deviceModel, 
    handlerModeChange, 
    handleDeviceModelChange
}: ModeAndModelSelectionProps) {
    return (
        <div className='selectionContainer'>
            <div className='modeSelection'>
                <FormControl fullWidth>
                    <InputLabel id='mode-label'>Mode</InputLabel>
                    <Select
                        id='mode-selection'
                        value={mode}
                        onChange={handlerModeChange}
                    >
                        {SupportedModes.map((value, index) => (
                            <MenuItem key={`mode-${index}`} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='modelSelection'>
                    <ModelSelection 
                        deviceModel={deviceModel}
                        handleDeviceModelChange={handleDeviceModelChange}
                    />
            </div>
        </div>
    )
}

export default ModeAndModelSelection;
