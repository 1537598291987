export enum HLAAResult {
  InvalidInput = 'Invalid tone sequence input',
  Workflow = 'Workflow',
  ToneSet = 'ToneSet',
  Threshold = 'Threshold'
}

export enum SpecialWorkflow {
  Reinstruct = 'Reinstruct',
  MaxPresentedTone = 'Max Presented Tone',
  ConstantZeroToneHeard = 'Constant Zero Tone Heard' 
}